import { AppliedFilters } from './FilterSearch';

export enum Frequency {
  DAILY = '1',
  WEEKLY = '7',
}
export interface StockAlert
  extends Omit<
    AppliedFilters,
    | 'branchId'
    | 'branchName'
    | 'deposit'
    | 'dor'
    | 'evCampaign'
    | 'locationLat'
    | 'locationLng'
    | 'page'
    | 'sortOrder'
    | 'unreservedOnly'
  > {
  frequency: Frequency | null;
}
