import request from 'superagent';
import type { StockReferences } from '@components/shortlist/shortlistStore';
import { Endpoint, HttpMethod, QueryString } from '@javascript/types/Requests';
import { getAuthHeaders, fetchWithAuthRetry } from './helpers/authHelpers';
import { csrfHeader } from './helpers/headerHelpers';

type ApiShortlistVehicle = {
  sold: boolean;
  stockReference: string;
};

export enum CounterOperation {
  increment = 'increment',
  decrement = 'decrement',
}

async function shortlistAPI(
  method: HttpMethod,
  options?: {
    url: Endpoint | QueryString;
    stockReferences?: StockReferences;
  }
) {
  const headerArgs = {
    ...(method !== HttpMethod.GET ? csrfHeader() : {}),
    ...(options?.stockReferences ? { 'Content-Type': 'application/json' } : {}),
  };
  const headers = await getAuthHeaders(headerArgs);
  const baseURL = '/api/shortlist';
  const requestUrl = options ? `${baseURL}${options.url}` : baseURL;

  return fetchWithAuthRetry(requestUrl, {
    headers,
    method,
    ...(options?.stockReferences
      ? { body: JSON.stringify({ stockReferences: options.stockReferences }) }
      : {}),
  });
}

export const addToShortlist = async (stockReference: StockReferences) =>
  shortlistAPI(HttpMethod.POST, {
    url: `?stockReference=${stockReference}`,
  });

export const deleteSoldVehicles = async () =>
  shortlistAPI(HttpMethod.DELETE, { url: '/deletesoldcars' });

export const getShortlist = async (): Promise<ApiShortlistVehicle[]> =>
  shortlistAPI(HttpMethod.GET).then((res) => res.json());

export const clearShortlist = async () =>
  shortlistAPI(HttpMethod.DELETE, { url: '/clear' });

export const mergeShortlist = async (
  stockReferences: StockReferences
): Promise<ApiShortlistVehicle[]> =>
  shortlistAPI(HttpMethod.PUT, { url: '/merge', stockReferences }).then((res) =>
    res.json()
  );

export const removeFromShortlist = async (stockReference: StockReferences) =>
  shortlistAPI(HttpMethod.DELETE, {
    url: `?stockReference=${stockReference}`,
  });

export const replaceShortlist = async (stockReferences: StockReferences) =>
  shortlistAPI(HttpMethod.PUT, { url: '/replace', stockReferences });

export const updateShortlistCount = (
  stockReferences: StockReferences,
  operation: CounterOperation
): void => {
  const stockRefArray = Array.isArray(stockReferences)
    ? stockReferences
    : [stockReferences];
  request
    .post('/shortlisted/count')
    .set(csrfHeader())
    .send({
      operation,
      stock_references: stockRefArray,
    })
    .end((err, res) => {
      if (err || !res.ok) {
        return;
      }
    });
};
