import React, { KeyboardEvent } from 'react';

interface FilterToggleProps {
  id: string;
  labelText: string | React.ReactNode;
  onChange: (id: string, checked: boolean) => void;
  checked?: boolean | string;
}

const FilterToggle = ({
  checked = false,
  id,
  labelText,
  onChange,
}: FilterToggleProps) => {
  const handleFilterToggleKeyDown = (
    event: KeyboardEvent<HTMLLabelElement>
  ) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onChange(id, !checked);
    }
  };

  const isChecked =
    typeof checked !== 'boolean' && checked.length > 0
      ? JSON.parse(checked)
      : checked;

  return (
    <>
      <label
        aria-checked={isChecked}
        className={`filter-toggle ${isChecked ? 'filter-toggle--checked' : ''}`}
        onKeyDown={handleFilterToggleKeyDown}
        role="checkbox"
        tabIndex={0}
      >
        <input
          checked={isChecked}
          className="ch-reader"
          onChange={() => onChange(id, !checked)}
          tabIndex={-1}
          type="checkbox"
        />
        {labelText}
        <span className="filter-toggle__indicator">
          <span className="filter-toggle__indicator-inner" />
        </span>
      </label>
    </>
  );
};

export default FilterToggle;
