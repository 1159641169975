import StockAlertLauncher from '@components/stock_alerts/stockAlertLauncher';
import { Endpoint } from '@javascript/types/Requests';

interface StockAlertMessageProps {
  stockAlertUrl?: Endpoint;
}

const StockAlertMessage = ({
  stockAlertUrl,
}: StockAlertMessageProps): JSX.Element => (
  <div className="ac-result ch-text--center ch-justify-content--center stock-alerts__card ch-pa--4 js-off--hide">
    <span className="stock-alerts__bell ch-mb--2"></span>
    <h3 className="ch-fs--4">Not seeing your perfect car?</h3>
    <p>We&apos;ll let you know as soon as it comes into stock</p>
    <StockAlertLauncher stockAlertUrl={stockAlertUrl} />
  </div>
);

export default StockAlertMessage;
