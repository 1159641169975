import { acMobileFeatures } from '@arnoldclark/ac-mobile-features';
import { useAccessToken } from '@arnoldclark/use-access-token';
import Auth from '@javascript/lib/auth';

const { isNativeApp } = acMobileFeatures();

const getAccessTokenSilently = () =>
  new Auth()
    .initializeAuthClient(window.authOptions)
    .then((client) => client.getTokenSilently());

// the linter thinks this is a React hook, it's not
// eslint-disable-next-line react-hooks/rules-of-hooks
export const { getAuthHeaders, fetchWithAuthRetry } = useAccessToken(
  isNativeApp(),
  getAccessTokenSilently
);
