import React from 'react';
import PropTypes from 'prop-types';

const RealSaleBanner = ({ text, isSale, isSERPS }) => {
  return (
    <div
      className={
        isSale
          ? `ac-promo--realsale ac-promo`
          : `ac-promo--approved-used ac-promo`
      }
    >
      <p
        className={`ch-mb--0 ac-promo__heading ch-fs--4 md:ch-fs--5 ${
          isSERPS && isSale ? 'ch-fw--600' : 'ch-fw--500'
        }`}
      >
        {text}
      </p>
    </div>
  );
};

RealSaleBanner.propTypes = {
  isSale: PropTypes.bool,
  isSERPS: PropTypes.bool,
  text: PropTypes.string,
};

export default RealSaleBanner;
