import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VehicleResults from './vehicleResults';
import { createStockAlertUrl } from '@javascript/stock_alerts/helpers';

const ShowMore = ({
  appliedFilters,
  authenticityToken,
  baseUrl,
  count,
  currentPage,
  experiments,
  isLoading,
  onFilterCriteriaUpdate,
  parameters,
  result,
}) => {
  const [progressPercentage, setProgressPercentage] = useState();
  const [isLastPage, setIsLastPage] = useState(false);

  const calculateRoundedPercentage = (num, total) => {
    if (num >= total) return 100;
    return Math.round((num / total) * 100);
  };

  useEffect(() => {
    setProgressPercentage(
      calculateRoundedPercentage(result.searchResults.length, count)
    );
    result.searchResults.length >= count
      ? setIsLastPage(true)
      : setIsLastPage(false);
  }, [result.searchResults]);

  const nextPage = (pageNumber) => pageNumber + 1;

  const urlForPage = (pageNumber) =>
    `${baseUrl}${parameters}${
      baseUrl.indexOf('?') === -1 ? '?page=' : '&page='
    }${pageNumber}`;

  const handleOnPageChanged = (event) => {
    event.preventDefault();
    onFilterCriteriaUpdate({ page: currentPage + 1 });
  };

  const stockAlertUrl = createStockAlertUrl(appliedFilters);

  return (
    <>
      <VehicleResults
        authenticityToken={authenticityToken}
        clickAndCollectDetails={result.clickAndCollectDetails}
        count={result.count}
        experiments={experiments}
        isLastPage={isLastPage}
        searchResults={result.searchResults}
        stockAlertUrl={stockAlertUrl}
        stockChipData={result.stockChipData}
      />
      <nav className="ch-pagination ch-mt--3 sm:ch-mt--4">
        <p className="ch-pagination__title ch-mb--3 ch-fs--3">
          Showing <strong>{result.searchResults.length}</strong> of{' '}
          <strong>{count}</strong> results
        </p>
        {result.searchResults.length < count && count !== 0 && count > 24 && (
          <>
            <div className="ch-progress ch-mb--3">
              <div
                className="ch-progress__bar"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>

            <a
              className={`ch-btn ch-mb--4 ch-width--12 sm:ch-width--auto ${
                isLoading && 'ch-btn--loading'
              }`}
              data-page-number={nextPage(currentPage)}
              href={urlForPage(nextPage(currentPage))}
              onClick={handleOnPageChanged}
              rel="nofollow"
            >
              Load more
            </a>
          </>
        )}
      </nav>
    </>
  );
};

ShowMore.propTypes = {
  appliedFilters: PropTypes.object,
  authenticityToken: PropTypes.string,
  baseUrl: PropTypes.string,
  clickAndCollectDetails: PropTypes.object,
  count: PropTypes.number,
  currentPage: PropTypes.number,
  experiments: PropTypes.object,
  isLoading: PropTypes.bool,
  onFilterCriteriaUpdate: PropTypes.func,
  parameters: PropTypes.string,
  result: PropTypes.object,
  stockChipData: PropTypes.object,
};

export default ShowMore;
