import EventEmitter, { EVENT_TYPES } from '@javascript/lib/eventEmitter';
import Modal from '../application/modal';

type UnverfiedProps = {
  customerEmail: string;
};

type StockAlertSuccessModalProps = Partial<UnverfiedProps>;

const Unverified = ({ customerEmail }: UnverfiedProps) => (
  <>
    <span className="ac-stock-alerts__success--icon ac-stock-alerts__success--icon-unverified ch-mh--auto ch-mv--3 sl:ch-mt--1 sl:ch-mb--4" />
    <h1 className="ch-fs--5">Check your emails</h1>
    <p className="ch-fs--3 ch-ph--2 ch-color--grey-6">
      Confirm your email address to get stock alerts. We’ve sent an email to{' '}
      <span className="ch-fw--500 ch-color--ac-black">{customerEmail}</span>
    </p>
  </>
);

const Verified = () => (
  <>
    <span className="ac-stock-alerts__success--icon ch-mh--auto ch-mv--3 sl:ch-mt--1 sl:ch-mb--4" />
    <h1 className="ch-fs--5">All done!</h1>
    <p className="ch-fs--3 ch-ph--2 ch-color--grey-6">
      Keep an eye on your inbox. We’ll let you know as soon as your perfect car
      comes into stock.
    </p>
  </>
);

const closeModal = () =>
  EventEmitter.emit(EVENT_TYPES.closeModal, 'stock-alert-success');

const StockAlertSuccessModal = ({
  customerEmail,
}: StockAlertSuccessModalProps) => (
  <Modal hideClose id="stock-alert-success" startOpen>
    <div className="ch-modal ch-modal--drawer ac-stock-alerts__success ac-modal__close--grey">
      <button className="ch-modal__close" onClick={closeModal} type="button">
        <span className="ch-reader">Close</span>
      </button>
      <div className="ch-modal__body ch-bg--grey-1 ch-ph--3 ch-pv--4 sl:ch-ph--6 sl:ch-pv--7 ch-text--center">
        {customerEmail ? (
          <Unverified customerEmail={customerEmail} />
        ) : (
          <Verified />
        )}
      </div>
    </div>
  </Modal>
);

export default StockAlertSuccessModal;
